define("discourse/plugins/discourse-mssociety-enforce-consent/discourse/components/consent-modal", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/url", "discourse/lib/waving-hand-url", "discourse/mixins/user-fields-validation", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _object, _computed, _ajax, _url, _wavingHandUrl, _userFieldsValidation, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @dismissable={{false}}
    class="consent-modal"
    @flash={{this.flash}}
    @flashType="error"
  >
    <:body>
      <div class="login-welcome-header">
        <h1 class="login-title">
          {{i18n
            "discourse_mssociety.welcome_back"
            username=this.accountUsername
          }}
          <img src={{this.wavingHandURL}} alt="" class="waving-hand" />
        </h1>
      </div>
  
      <p class="login-subheader">
        {{i18n "discourse_mssociety.consent_subheader"}}
      </p>
  
      {{#if this.userFields}}
        <div class="ms-user-fields">
          {{#each this.userFields as |f|}}
            {{#if
              (eq this.siteSettings.mssociety_consent_field_name f.field.name)
            }}
              <div class="input-group">
                {{user-field field=f.field value=f.value}}
              </div>
            {{/if}}
          {{/each}}
        </div>
      {{/if}}
    </:body>
    <:footer>
      {{d-button
        class="btn-large btn-primary"
        action=(action "submitUpdate")
        disabled=this.submitDisabled
        label="discourse_mssociety.consent_submit"
        isLoading=this.formSubmitted
      }}
    </:footer>
  </DModal>
  */
  {
    "id": "tmLlzvVd",
    "block": "[[[8,[39,0],[[24,0,\"consent-modal\"]],[[\"@dismissable\",\"@flash\",\"@flashType\"],[false,[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"login-welcome-header\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"login-title\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"discourse_mssociety.welcome_back\"],[[\"username\"],[[30,0,[\"accountUsername\"]]]]]],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"wavingHandURL\"]]],[14,\"alt\",\"\"],[14,0,\"waving-hand\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[14,0,\"login-subheader\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"discourse_mssociety.consent_subheader\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"userFields\"]],[[[1,\"      \"],[10,0],[14,0,\"ms-user-fields\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"userFields\"]]],null]],null],null,[[[41,[28,[37,10],[[30,0,[\"siteSettings\",\"mssociety_consent_field_name\"]],[30,1,[\"field\",\"name\"]]],null],[[[1,\"            \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n              \"],[1,[28,[35,11],null,[[\"field\",\"value\"],[[30,1,[\"field\"]],[30,1,[\"value\"]]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[1,[28,[35,13],null,[[\"class\",\"action\",\"disabled\",\"label\",\"isLoading\"],[\"btn-large btn-primary\",[28,[37,14],[[30,0],\"submitUpdate\"],null],[30,0,[\"submitDisabled\"]],\"discourse_mssociety.consent_submit\",[30,0,[\"formSubmitted\"]]]]]],[1,\"\\n  \"]],[]]]]]],[\"f\"],false,[\"d-modal\",\":body\",\"div\",\"h1\",\"i18n\",\"img\",\"p\",\"if\",\"each\",\"-track-array\",\"eq\",\"user-field\",\":footer\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-mssociety-enforce-consent/discourse/components/consent-modal.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(_userFieldsValidation.default, dt7948.p({
    formSubmitted: false,
    accountUsername: (0, _computed.alias)("currentUser.username"),
    originalUsernameLower: (0, _computed.readOnly)("currentUser.username_lower"),
    init() {
      this._super();
      this._createUserFields();
    },
    wavingHandURL: () => (0, _wavingHandUrl.wavingHandURL)(),
    submitDisabled() {
      const validation = [this.userFieldsValidation].find(v => {
        return v && v.failed;
      });
      return validation;
    },
    submitUpdate() {
      this.set("flash", null);
      const userFields = {};
      if (this.userFields) {
        this.userFields.forEach(f => userFields[f.get("field.id")] = f.get("value"));
      }
      return (0, _ajax.ajax)((0, _url.userPath)(`${this.currentUser.username_lower}.json`), {
        data: {
          user_fields: userFields
        },
        type: "PUT"
      }).then(result => {
        if (result.success) {
          this.closeModal();
        } else {
          this.set("flash", result.message || _I18n.default.t("discourse_mssociety.generic_error"), "error");
        }
      }).catch(error => {
        this.set("flash", error.message || _I18n.default.t("discourse_mssociety.generic_error"));
      });
    }
  }, [["field", "wavingHandURL", [(0, _decorators.default)()]], ["method", "submitDisabled", [(0, _decorators.default)("userFields.@each.value")]], ["method", "submitUpdate", [_object.action]]])));
});