define("discourse/plugins/discourse-mssociety-enforce-consent/discourse/initializers/ms-enforce-consent-checkbox", ["exports", "@ember/runloop", "discourse/lib/plugin-api", "discourse/plugins/discourse-mssociety-enforce-consent/discourse/components/consent-modal"], function (_exports, _runloop, _pluginApi, _consentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "ms-enforce-consent-checkbox",
    after: "auth-complete",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        if (!document.getElementById("data-authentication")) {
          const currentUser = api.getCurrentUser();
          if (currentUser && typeof currentUser.has_given_consent !== "undefined" && !currentUser.has_given_consent) {
            container.lookup("service:router").one("routeDidChange", () => {
              (0, _runloop.next)(() => container.lookup("service:modal").show(_consentModal.default));
            });
          }
        }
      });
    }
  };
});